import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild, AfterViewChecked, AfterViewInit, DoCheck, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ImageService, I18nService, PlatformHelperService } from '@app/core';
import { CommonGenericService } from '@app/core/common.service';
import { PopoverCtrlHelper } from '@app/core/helpers/popover-ctrl-helper.service';
import { CompanyPhotoDTO } from '@app/models/company-photo.model';
import { CompanyDTO } from '@app/models/company.model';
import { SpotImageDTO } from '@app/models/spot-buildings/spot-image.model';
import { faChevronLeft, faChevronRight, faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { CompanyService } from '../services/company.service';
import { SocialMediaInput } from '../../models/social-media.model';
import { SpotBuildingsSearchService } from '../../spot-buildings/services/spot-buildings-search.service';
import { SpotBuildingSearchRequest } from '../../models/spot-buildings/spot-building-search.model';
import { SpotBuildingDetailDTO } from '@app/models/spot-buildings/spot-buildings.model';
import { LocationService } from '../../shared/services/location.service';
import { IDNameDTO } from '@app/models/id-name.model';
import { StateDTO } from '../../models/state.model';
import { stat } from 'fs';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { AgentDTO } from '../../models/spot-buildings/agent-broker.model';
import { IonSlides } from '@ionic/angular';
import { RoutingHelperService } from '../../core/helpers/routing-helper.service';
import { environment } from '@env/environment';
import { Location } from '@angular/common';
import { MetaService } from '@app/core/seo';
import { UserActivityService } from '@app/core/user-activity/user-activity.service';
import { UserActivity, UserActivityType } from '@app/models/user-activity.model';
import { CompanyMetatag } from '@app/models/spot-buildings/spot-buildings-types';
@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss']
})
export class CompanyDetailsComponent implements OnInit {
  displayScrollToTop: boolean = false;
  isMobile: boolean;
  companyId: number;
  company: CompanyDTO;
  companyName: string;
  companyPhotos: CompanyPhotoDTO[];
  agentsDTO: AgentDTO[] = [];
  spotBuildingNames: string[] = [];
  totalDtos: string[] = [];
  defaultImage: string;
  sharedIcon: any;
  companyBio: string;
  spotBuildingSearchRequest: SpotBuildingSearchRequest;

  sliderPreviewOptions: any;
  @ViewChild('previewSlider', { static: false }) previewSlider: IonSlides;
  currentPhotoIndex: number = 0;
  prevCurrentPhotoIndex: number = 0;
  rightArrow: any;
  leftArrow: any;
  screenWidth: number;
  visibleAgentCards: number;
  searching: boolean = false;
  continueReading: boolean = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private i18NService: I18nService,
    private breakpointObserver: BreakpointObserver,
    private companyService: CompanyService,
    private routingHelperSrv: RoutingHelperService,
    private platformConfigHelper: PlatformHelperService,
    private imageService: ImageService,
    private popOverCtrlHelper: PopoverCtrlHelper,
    private userActivityService: UserActivityService,
    private spotBuildingSearchService: SpotBuildingsSearchService,
    private locationService: LocationService,
    private metaService: MetaService,
    private _location: Location
  ) {
    this.screenWidth = window.innerWidth;

    this.findTotalAgentCards();
    this.breakpointObserver.observe(Breakpoints.Handset).subscribe(result => {
      this.isMobile = result.matches;
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenWidth = event.target.innerWidth;
    this.findTotalAgentCards();
  }

  findTotalAgentCards() {
    this.visibleAgentCards = 3;
    if (this.screenWidth < 1024) {
      this.visibleAgentCards = 1;
    } else if (this.screenWidth < 1200) {
      this.visibleAgentCards = 2;
    }
    this.sliderPreviewOptions = {
      slidesPerView: this.visibleAgentCards,
      spaceBetween: 100,
      pagination: true
    };
    this.updateDots();
  }

  updateDots() {
    this.totalDtos = [];
    for (const agent in this.agentsDTO) {
      if (parseInt(agent) + this.visibleAgentCards > this.agentsDTO.length) {
        break;
      }
      this.totalDtos.push(agent);
    }
  }

  ngOnInit() {
    this.sharedIcon = faShareAlt;
    this.leftArrow = faChevronLeft;
    this.rightArrow = faChevronRight;
    this.defaultImage = this.platformConfigHelper.Defaults().imagePlaceholder;
    this.getParamsUrl();
  }

  async getParamsUrl() {
    this.activatedRoute.params.subscribe(async paramMap => {
      try {
        this.companyId = paramMap.companyId;
        this.spotBuildingSearchRequest = { companyId: this.companyId };
        this.spotBuildingSearchRequest.listingClasses = ['FEATURED', 'PREMIUM'];

        this.companyService.getCompanyMetadataById(this.companyId).then((companyData: CompanyMetatag) => {
          this.metaService.setCompanyMetaTagsLight(companyData);
          (window as any).prerenderReady = true;
        });

        this.companyService.getCompanyById(this.companyId).then((company: any) => {
          this.company = company;
          this.registerUserActivity(UserActivityType.PAGEVIEW_COMPANY, null, this.company.id);
          this.companyName = company ? company.name : '';
          this.companyBio = this.company ? this.getDefaultTranslation(this.company.biography) : '';

          this.companyService.getPhotosByCompanyId(this.companyId).then((photos: any) => {
            this.companyPhotos = photos;
            this.companyService.getAgentsWithSpotsFrom(this.companyId).then((result: any) => {
              this.agentsDTO = result;
              this.companyService.getSpotTitlesByCompanyId(this.companyId).then((result: any) => {
                this.spotBuildingNames = result;
                //this.metaService.setCompanyMetaTags(this.company, this.agentsDTO, this.spotBuildingNames);
              });
              this.updateDots();
            });
          });
        });
      } catch (error) {
        console.error('Error : ', error);
      }
    });
  }

  public getLargeImg(id: any) {
    return this.imageService.largeThumbnail(id);
  }

  public getMediumImg(id: any) {
    return this.imageService.mediumThumbnail(id);
  }

  getCroppedOrOriginalImage(photo: any) {
    if (photo) {
      if (photo.croppedImageId) {
        return photo.croppedImageId;
      }
      return photo.imageId;
    }
  }

  openGalleryImg(event: Event): void {
    let photos: SpotImageDTO[] = [];
    if (this.companyPhotos) {
      photos = this.companyPhotos.map(blp => {
        const photo: SpotImageDTO = {
          id: blp.id,
          croppedImageId: blp.croppedImageId,
          imageId: blp.imageId,
          orderId: blp.orderId,
          originalImageId: blp.originalImageId
        };
        return photo;
      });
    }
    this.popOverCtrlHelper.openGalleryImageComponent(event, photos, this.company.name);
  }

  private buildTitleMetatag(company: CompanyDTO) {
    let metaTagTitle = '';

    const companyTitleTag = company.name;

    metaTagTitle = `${companyTitleTag} | SiiLA SPOT`;

    // KeyWord Metatag
    let keywordsMetaTag = '';
    let companyName = '';

    const keywordsMetaTags = [];
    keywordsMetaTags.push(companyTitleTag);

    keywordsMetaTag = keywordsMetaTags.join();
    return { metaTagTitle, keywordsMetaTag };
  }

  private setCompanyMetaTags() {
    const detailsMetaTags = this.metaService.buildCompanyMetatag(this.company, this.agentsDTO, this.spotBuildingNames);
    const fullTitleText = detailsMetaTags.metaTagTitle;
    const keywordsMetaTag = detailsMetaTags.keywordsMetaTag;
    const descText = this.company.biography
      ? this.removeHTMLCode(this.i18NService.getTranslation(this.company.biography))
      : '';
    const locale = this.i18NService.getCurrentLocale();
    const companyTitle = this.i18NService.getTranslation(this.company.name);
    const fullDetailsURL = window.location.href;

    const tags = [
      //change to be same as title
      { name: 'name', content: encodeURIComponent(fullTitleText) },
      //{ name: 'og:name', content: encodeURIComponent('SiiLA SPOT') },
      { name: 'og:locale', content: [locale] },
      { name: 'keywords', content: keywordsMetaTag },
      { name: 'title', content: [fullTitleText] },
      {
        name: 'mobile-web-app-title',
        content: encodeURIComponent(fullTitleText)
      },
      { name: 'description', content: [descText] },
      { name: 'author', content: 'SiiLA' },

      { name: 'og:url', content: `${encodeURI(fullDetailsURL)}` },
      { name: 'og:site_name', content: 'SiiLA SPOT' },
      { name: 'og:type', content: 'article' },
      { name: 'og:title', content: [companyTitle] },
      { name: 'og:description', property: 'og:description', content: descText },
      { name: 'og:site_url', content: `${encodeURI(fullDetailsURL)}` },
      {
        name: 'og:image',
        property: 'og:image',
        content:
          this.company.mainImageId != undefined
            ? encodeURI(`${environment.spotServerUrl}${this.getMediumImg(this.company.mainImageId)}`)
            : null
      },
      {
        name: 'image',
        content:
          this.company.mainImageId != undefined
            ? encodeURI(`${environment.spotServerUrl}${this.getMediumImg(this.company.mainImageId)}`)
            : null
      },
      { name: 'twitter:card', content: `summary_large_image` },
      { name: 'twitter:title', content: [companyTitle] },
      {
        name: 'twitter:image',
        content:
          this.company.mainImageId != undefined
            ? encodeURI(`${environment.spotServerUrl}${this.getMediumImg(this.company.mainImageId)}`)
            : null
      },
      { name: 'twitter:description', content: descText }
    ];

    this.metaService.setDynamicTags(tags, fullTitleText);
  }

  removeHTMLCode(htmlText: string) {
    var div = document.createElement('div');
    div.innerHTML = htmlText;
    var text = div.textContent || div.innerText || '';
    return text;
  }

  getDefaultTranslation(translationArtifact: any) {
    try {
      return this.i18NService.getTranslation(translationArtifact);
    } catch (e) {
      console.log(e);
    }
  }

  shareCompany(event: any) {
    const url = window.location.href;
    const sharedTitle = this.company.name;
    const modalTitle = this.i18NService.get('ShareCompanyProfile');
    this.userActivityService.trackCompanyShare(this.company.id);
    const input: SocialMediaInput = { url, sharedTitle, modalTitle };

    this.popOverCtrlHelper.openSocialMediaPopup(event, input);
  }

  public getNextPhoto($event: Event) {
    this.previewSlider.getActiveIndex().then(index => {
      if (index < this.currentPhotoIndex) {
        this.currentPhotoIndex = index;
      } else {
        if ($event.type === 'click') {
          this.previewSlider.slideNext();
          this.updatePhotoIndex();
        } else {
          this.updatePhotoIndex();
        }
      }
    });
  }

  public updatePhotoIndex() {
    this.previewSlider.getActiveIndex().then(index => {
      if (index != this.currentPhotoIndex) {
        this.currentPhotoIndex = index;
        this.syncSliders();
      }
    });
  }

  public isStartOfSlide(): boolean {
    return this.currentPhotoIndex <= 0;
  }

  public isEndOfSlide(): boolean {
    return this.currentPhotoIndex + this.visibleAgentCards >= this.agentsDTO.length;
  }

  arrowsNavigation(event: Event, index: number) {
    const toIndex = this.currentPhotoIndex + index;
    this.slideToIndex(event, toIndex);
  }

  slideToIndex(event: Event, index: number) {
    event.stopPropagation();
    this.currentPhotoIndex = index;
    this.syncSliders();
  }

  syncSliders() {
    this.previewSlider.slideTo(this.currentPhotoIndex);
  }

  isFooterVisible(event: any) {
    this.displayScrollToTop = event.visible;
  }

  navigateToBroker(brokerId: any) {
    this.routingHelperSrv.navigateToBrokerDetailsPage(brokerId);
  }

  registerUserActivity(userActivityType: UserActivityType, brokerId: number, companyId: number) {
    const userActivity: UserActivity = {
      module: userActivityType,
      brokerId: brokerId,
      companyId: companyId
    };
    this.userActivityService.saveUserActivity(userActivity);
  }
}
