import { Injectable } from '@angular/core';
import { I18nService } from '@app/core';
import { CommonGenericService } from '@app/core/common.service';
import { NearbyListingSvc } from '@app/core/listings/nearby-listing.service';
import { LocationService } from '@app/shared/services/location.service';
import { HttpClient } from '@angular/common/http';
import { tap, map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { BaseService } from './base-service';
import { Location } from '@angular/common';
import { SetReport } from '@app/models/set-report.model';
import { SpotSet } from '@app/models/spotSet';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable()
export class SpotBuildingsService extends BaseService {
  // APIs
  private spotPhotosAPI = `/api/buildinglistingPhotos`;
  private buildinglistingResource = `/api/buildinglisting`;
  private detailUrlDataAPI = `${this.buildinglistingResource}/{id}/detail/url-data/{lang}`;
  private logAPI = `/api/logs/log`;
  private spotSetAPI = '/api/spotset/';
  private spotCover = '/api/spot-cover';
  private downloadSetReport = 'api/reports/files/';

  constructor(
    private http: HttpClient,
    private i18nService: I18nService,
    private _location: Location,
    private deviceDetectorService: DeviceDetectorService
  ) {
    super();
  }

  logError(error: string) {
    const logMessage = { message: error };
    this.http
      .post(this.logAPI, logMessage)
      .toPromise()
      .then(() => {});
  }

  getPhotosFromSpot(id: number) {
    const url = super.interporlateURL(`${this.spotPhotosAPI}/:id`, { id: id });

    return this.http.get(url, { observe: 'response' }).pipe(map(val => val.body));
  }

  getSpotBuildingDetailURLFormat(id: any, lang?: string) {
    const url = super.interporlateURL(this.detailUrlDataAPI, { id: id, lang: lang });

    return this.http.get(url, { observe: 'response' }).pipe(map(val => val.body));
  }

  getServiceProvider(spotId: number) {
    return this.http
      .get(
        'api/service-provider/carousel-data?application=SPOT&size=5' + (spotId ? '&buildingListingId=' + spotId : '')
      )
      .pipe(map(val => val))
      .toPromise();
  }

  async goToSpotDetailsURL(spotId: number) {
    let lang = this.i18nService.getCurrentLanguage();
    if (window.location.href.includes('/es')) {
      lang = 'es';
    } else if (window.location.href.includes('/en')) {
      lang = 'en';
    }

    let res: any = await this.getSpotBuildingDetailURLFormat(spotId, lang).toPromise();
    if (res) {
      let detailsURL = res.detailsURL;
      if (this.i18nService.currentUrlContainsCurrentLanguage()) {
        detailsURL = this.i18nService.addCurrentLanguageToPath(detailsURL);
      }
      this._location.go(detailsURL);
      return detailsURL;
    }
  }

  setSpotSpace(spotSet: SpotSet) {
    return this.http.post(this.spotSetAPI, spotSet).toPromise();
  }

  getSpotSet() {
    return this.http.get(`${this.spotSetAPI}spot`).toPromise();
  }

  putSpotSet(spotSet: SpotSet) {
    return this.http
      .put(this.spotSetAPI, spotSet, {
        observe: 'response'
      })
      .pipe(
        map(val => {
          return val.status;
        })
      )
      .toPromise()
      .catch(val => {
        return val.status;
      });
  }

  patchSpotSet(spotSet: SpotSet) {
    return this.http
      .patch(this.spotSetAPI, spotSet, {
        observe: 'response'
      })
      .pipe(
        map(val => {
          return val.status;
        })
      )
      .toPromise()
      .catch(val => {
        return val.status;
      });
  }

  getbuildinglistingSpaces(spaces: number[]) {
    return this.http.post(`${this.buildinglistingResource}/spaces/spots`, spaces).toPromise();
  }

  createReport(setReport: SetReport) {
    return this.http.post(`${this.spotCover}/report`, setReport).toPromise();
  }

  downloadCoverReportt(fileName: string, isSafariIos: boolean) {
    this.downloadPDF(fileName, isSafariIos);
  }

  downloadPDF(fileName: string, isSafariIos?: boolean) {
    if (isSafariIos || window.matchMedia('(display-mode: standalone)').matches) {
      if (this.deviceDetectorService.os.toLowerCase() === 'android') {
        var link = document.createElement('a');
        link.href = this.downloadSetReport + fileName;
        link.target = '_blank';
        link.dispatchEvent(new MouseEvent('click'));
      } else {
        var xhr = new XMLHttpRequest();
        xhr.open('GET', this.downloadSetReport + fileName, true);
        xhr.responseType = 'blob';
        xhr.onload = function(e) {
          if (this.status == 200) {
            var myBlob = this.response;
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(myBlob);
            link.target = '_blank';
            link.download = fileName;
            link.click();
          }
        };
      }

      xhr.send();
    } else {
      var link = document.createElement('a');
      link.href = this.downloadSetReport + fileName;
      //link.download = fileName;
      link.target = '_blank';
      link.dispatchEvent(new MouseEvent('click'));
    }
  }
  excludeSpotDetails(ids: number[]) {
    return this.http.post(`${this.spotCover}/config/validate`, ids).toPromise();
  }
}
