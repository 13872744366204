import * as tslib_1 from "tslib";
import { I18nService } from '@app/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BaseService } from './base-service';
import { Location } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';
export class SpotBuildingsService extends BaseService {
    constructor(http, i18nService, _location, deviceDetectorService) {
        super();
        this.http = http;
        this.i18nService = i18nService;
        this._location = _location;
        this.deviceDetectorService = deviceDetectorService;
        // APIs
        this.spotPhotosAPI = `/api/buildinglistingPhotos`;
        this.buildinglistingResource = `/api/buildinglisting`;
        this.detailUrlDataAPI = `${this.buildinglistingResource}/{id}/detail/url-data/{lang}`;
        this.logAPI = `/api/logs/log`;
        this.spotSetAPI = '/api/spotset/';
        this.spotCover = '/api/spot-cover';
        this.downloadSetReport = 'api/reports/files/';
    }
    logError(error) {
        const logMessage = { message: error };
        this.http
            .post(this.logAPI, logMessage)
            .toPromise()
            .then(() => { });
    }
    getPhotosFromSpot(id) {
        const url = super.interporlateURL(`${this.spotPhotosAPI}/:id`, { id: id });
        return this.http.get(url, { observe: 'response' }).pipe(map(val => val.body));
    }
    getSpotBuildingDetailURLFormat(id, lang) {
        const url = super.interporlateURL(this.detailUrlDataAPI, { id: id, lang: lang });
        return this.http.get(url, { observe: 'response' }).pipe(map(val => val.body));
    }
    getServiceProvider(spotId) {
        return this.http
            .get('api/service-provider/carousel-data?application=SPOT&size=5' + (spotId ? '&buildingListingId=' + spotId : ''))
            .pipe(map(val => val))
            .toPromise();
    }
    goToSpotDetailsURL(spotId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let lang = this.i18nService.getCurrentLanguage();
            if (window.location.href.includes('/es')) {
                lang = 'es';
            }
            else if (window.location.href.includes('/en')) {
                lang = 'en';
            }
            let res = yield this.getSpotBuildingDetailURLFormat(spotId, lang).toPromise();
            if (res) {
                let detailsURL = res.detailsURL;
                if (this.i18nService.currentUrlContainsCurrentLanguage()) {
                    detailsURL = this.i18nService.addCurrentLanguageToPath(detailsURL);
                }
                this._location.go(detailsURL);
                return detailsURL;
            }
        });
    }
    setSpotSpace(spotSet) {
        return this.http.post(this.spotSetAPI, spotSet).toPromise();
    }
    getSpotSet() {
        return this.http.get(`${this.spotSetAPI}spot`).toPromise();
    }
    putSpotSet(spotSet) {
        return this.http
            .put(this.spotSetAPI, spotSet, {
            observe: 'response'
        })
            .pipe(map(val => {
            return val.status;
        }))
            .toPromise()
            .catch(val => {
            return val.status;
        });
    }
    patchSpotSet(spotSet) {
        return this.http
            .patch(this.spotSetAPI, spotSet, {
            observe: 'response'
        })
            .pipe(map(val => {
            return val.status;
        }))
            .toPromise()
            .catch(val => {
            return val.status;
        });
    }
    getbuildinglistingSpaces(spaces) {
        return this.http.post(`${this.buildinglistingResource}/spaces/spots`, spaces).toPromise();
    }
    createReport(setReport) {
        return this.http.post(`${this.spotCover}/report`, setReport).toPromise();
    }
    downloadCoverReportt(fileName, isSafariIos) {
        this.downloadPDF(fileName, isSafariIos);
    }
    downloadPDF(fileName, isSafariIos) {
        if (isSafariIos || window.matchMedia('(display-mode: standalone)').matches) {
            if (this.deviceDetectorService.os.toLowerCase() === 'android') {
                var link = document.createElement('a');
                link.href = this.downloadSetReport + fileName;
                link.target = '_blank';
                link.dispatchEvent(new MouseEvent('click'));
            }
            else {
                var xhr = new XMLHttpRequest();
                xhr.open('GET', this.downloadSetReport + fileName, true);
                xhr.responseType = 'blob';
                xhr.onload = function (e) {
                    if (this.status == 200) {
                        var myBlob = this.response;
                        var link = document.createElement('a');
                        link.href = window.URL.createObjectURL(myBlob);
                        link.target = '_blank';
                        link.download = fileName;
                        link.click();
                    }
                };
            }
            xhr.send();
        }
        else {
            var link = document.createElement('a');
            link.href = this.downloadSetReport + fileName;
            //link.download = fileName;
            link.target = '_blank';
            link.dispatchEvent(new MouseEvent('click'));
        }
    }
    excludeSpotDetails(ids) {
        return this.http.post(`${this.spotCover}/config/validate`, ids).toPromise();
    }
}
