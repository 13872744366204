import * as tslib_1 from "tslib";
import { I18nService } from '../i18n.service';
import { BaseService } from '../../spot-buildings/services/base-service';
import { Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AppRouteNamesService } from './app-route-names.service';
import { TranslateService } from '@ngx-translate/core';
import { CompanyService } from '@app/company/services/company.service';
import { BrokersService } from '@app/brokers/services/brokers.service';
export class RoutingHelperService extends BaseService {
    constructor(router, i18nService, breakpointObserver, appRouteNamesSrv, translateService, companyService, brokerService) {
        super();
        this.router = router;
        this.i18nService = i18nService;
        this.breakpointObserver = breakpointObserver;
        this.appRouteNamesSrv = appRouteNamesSrv;
        this.translateService = translateService;
        this.companyService = companyService;
        this.brokerService = brokerService;
        this.isMobile = false;
        this.getSpotSearchURL = () => `/${this.appRouteNamesSrv.getSearchRouteName()}`;
        this.breakpointObserver.observe(Breakpoints.Handset).subscribe(result => {
            this.isMobile = result.matches;
        });
        this.translateService.onLangChange.subscribe((event) => {
            //console.log('Lang changed Routing helpser ', event, this.getSpotSearchURL());
        });
    }
    concatLanguageToURL(url) {
        return this.i18nService.concatLangParamBasedOnURL(url);
    }
    navigateTo(url, state, samePage) {
        url = this.concatLanguageToURL(url);
        if (this.isMobile || samePage) {
            this.router.navigateByUrl(url, { state: state });
        }
        else {
            window.open(url, '_blank');
        }
    }
    navigateToBrokerDetailsPage(brokerId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const url = yield this.brokerService.getBrokerURL(brokerId);
            this.navigateTo(url.detailsURL);
        });
    }
    navigateToCompanyDetailsPage(companyId, state) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const companyURLFormat = yield this.companyService.getCompanyDetailURL(companyId);
            let detailsURL = companyURLFormat.detailsURL;
            this.navigateTo(detailsURL);
        });
    }
    navigateToSearchPage(avoidTargetBlank) {
        if (avoidTargetBlank) {
            this.router.navigateByUrl(this.getSpotSearchURL());
        }
        else {
            this.navigateTo(this.getSpotSearchURL());
        }
    }
    navigateToDefaultListingPropertySearchURL() {
        let url = `${this.getSpotSearchURL()}${this.appRouteNamesSrv.getDefaultInitSearchRouteName()}`;
        this.navigateTo(url, null, true);
    }
    navigateFavoritesPage() {
        const favoritesPath = `/${this.appRouteNamesSrv.getMySpotsRouteName()}`;
        this.navigateTo(favoritesPath, null, true);
    }
    navigateToSpotHomePage() {
        this.navigateTo('/', null, true);
    }
    navigateToSavedSearchPage(savedSearch) {
        const url = `${this.getSpotSearchURL()}/${this.appRouteNamesSrv.getSavedSearchRouteName()}/${savedSearch.user.id}/${savedSearch.id}`;
        if (this.isMobile) {
            this.router.navigateByUrl(url);
        }
        else {
            window.open(url, '_blank');
        }
    }
    navigateToSpotSetSearchPage(item) {
        let newUrl = `${item.spotSearchURL}/${this.concatLanguageToURL(this.appRouteNamesSrv.getFilterRouteName())}?${this.appRouteNamesSrv.getSpotSetRouteName()}=${item.name}`;
        this.router.navigateByUrl(`${this.getSpotSearchURL()}${newUrl}`);
    }
    navigateToTermsPage() {
        this.navigateTo(this.appRouteNamesSrv.getTermsRouteName(), null, true);
    }
    navigateToPrivacyPolicyPage() {
        this.navigateTo(this.appRouteNamesSrv.getPrivacyPolyRouteName(), null, true);
    }
}
