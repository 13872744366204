import * as tslib_1 from "tslib";
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { AccountService } from './account.service';
import { RegisterService } from '@app/core/register.service';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { ImageService } from '../image.service';
import { WebsocketService, SiilaBusAuthActions } from '@app/websockets';
import { MyMessageService } from '@app/user-home/my-message/my-message.service';
import { SocketIOService } from '@app/chat/socket-io.service';
import { I18nService } from '../i18n.service';
import { ListingSearchService } from '@app/search-navigation/services/listing-search.service';
import { SelectedFilterKey } from '@app/search-navigation/pages/listing-search/filter-selected-keys';
export class AuthenticationService {
    constructor(http, platformId, account, registerService, router, alertCtrl, imageservice, websocketService, myMessageService, socketService, i18nService, listingSearchService) {
        this.http = http;
        this.platformId = platformId;
        this.account = account;
        this.registerService = registerService;
        this.router = router;
        this.alertCtrl = alertCtrl;
        this.imageservice = imageservice;
        this.websocketService = websocketService;
        this.myMessageService = myMessageService;
        this.socketService = socketService;
        this.i18nService = i18nService;
        this.listingSearchService = listingSearchService;
        this.host = 'host';
        this.dev = '/oauth/token';
        this.sessionPersistenceEndpoint = '/api/authenticate';
        this.$isLoginSubject = new BehaviorSubject(this.hasToken());
        this.loadUnreadMessages();
        window.addEventListener('storage', this.message_receive);
    }
    message_broadcast(message) {
        localStorage.setItem('message', message);
        localStorage.removeItem('message');
    }
    message_receive(ev) {
        if (ev.key == 'message') {
            var message = ev.newValue;
            if (message && (message == 'logout' || message == 'login')) {
                console.log('Received login/logout cross-tab call');
                window.location.href = environment.spotServerUrl;
            }
        }
    }
    loadUnreadMessages() {
        if (localStorage.getItem('unreadCount')) {
            this.myMessageService.setUnreadCount(parseInt(localStorage.getItem('unreadCount')));
        }
    }
    /**
     * if we have token the user is loggedIn
     * @returns {boolean}
     */
    hasToken() {
        return !!localStorage.getItem('token');
    }
    getToken() {
        return localStorage.getItem('token') || '';
    }
    isLoggedIn() {
        return this.$isLoginSubject.asObservable();
    }
    isSessionDestroyed() {
        return this.http.get(this.sessionPersistenceEndpoint);
    }
    submitLoginCredentials(username, password, keepUrlOnBadCredentials = false) {
        const body = new HttpParams()
            .set('username', username)
            .set('password', password)
            .set('secret', 'mySecretOAuthSecret')
            .set('grant_type', 'password')
            //.set('scope', 'read write')
            .set('scope', 'spot')
            .set('client_id', 'siilaapp');
        return this.http
            .post(this.dev, body, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Accept', 'application/json')
                .set('Authorization', 'Basic c2lpbGFhcHA6bXlTZWNyZXRPQXV0aFNlY3JldA==')
        })
            .toPromise()
            .then(res => {
            if (isPlatformBrowser(this.platformId)) {
                localStorage.setItem('token', JSON.stringify(res));
            }
            this.$isLoginSubject.next(true);
            return;
        })
            .then(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const account = yield this.account.getAccount().toPromise();
            const userPhotos = yield this.account.getUserPhotoList().toPromise();
            const image = this.account.getUploadedImageId(userPhotos);
            const profileImage = this.imageservice.mediumThumbnail(image.profileImageId);
            localStorage.setItem('profileImage', profileImage);
            localStorage.setItem('unreadCount', this.myMessageService.getUnreadMessages() + '');
            localStorage.setItem('user', JSON.stringify(account));
            this.account.currentUser$.next(account);
            if (account && account.company && account.company.avoidPiwikTracking === true) {
                localStorage.setItem('userType', 'SiiLA');
            }
            else {
                localStorage.setItem('userType', 'Customer');
            }
            localStorage.setItem('userCompany', account.company.name);
            this.listingSearchService.restoreBackSelectedFiltersFrom(SelectedFilterKey.MY_LISTINGS, account);
            this.listingSearchService.restoreBackSelectedFiltersFrom(SelectedFilterKey.LISTING_ANALYTICS, account);
            sessionStorage.removeItem(SelectedFilterKey.SPOT_BUILDING_SEARCH);
            sessionStorage.removeItem(SelectedFilterKey.HOME_SPOT_SEARCH_FILTERS);
            const tokenValue = JSON.parse(localStorage.getItem('token'));
            const user = account;
            this.userAccount = account;
            this.websocketService.send(SiilaBusAuthActions.AUTHORIZE, { tokenValue, user });
            this.socketService.updateUnreadMessageCount();
            this.socketService.setCurrentUser();
            this.socketService.connect();
            this.message_broadcast('login');
            // const userDevices = await this.deviceRegistration.retrieveUserDevices();
            return {
                account: account,
                // devices: userDevices,
                registrationRequired: account.deviceRegistrationRequired
            };
        }))
            .catch(e => {
            const errorDescription = e.error && (e.error.error_description || 'error');
            switch (e.error.error_description) {
                case 'LOCKED_ACCOUNT':
                    console.log('LOCKED');
                    this.showMessage('Account Locked', 'Your account has been locked.');
                    break;
                case 'INACTIVE_ACCOUNT':
                    console.log('INACTIVE');
                    this.showMessage('Inactive Account/Company', 'Either your company or your account has been deactivated.');
                    break;
                case 'INACTIVE_SPOT_ACCOUNT':
                    const inactiveAccount = this.i18nService.get('global.inactiveAccount');
                    const accountConfirmation = this.i18nService.get('global.AccountConfirmation');
                    this.showMessage(inactiveAccount, accountConfirmation, false, 'email-confirm-height');
                    break;
                default:
                    if (e.error.error_description.slice(0, 22) == 'SHOULD_CHANGE_PASSWORD') {
                        this.registerService.setKeyForChangePassword(e.error.error_description.split(':')[1]);
                        this.router.navigateByUrl('/change-password');
                    } /* else {
                  const badCredentialsMsg = this.i18nService.get('global.badCredentials');
                  const badCredentialsDesc = this.i18nService.get('global.badCredentialsDesc');
                  this.showMessage(badCredentialsMsg, badCredentialsDesc, keepUrlOnBadCredentials);
                } */
            }
            return {
                error: errorDescription
            };
        });
    }
    refreshAccessToken() {
        const refreshToken = this.getToken() ? JSON.parse(this.getToken()).refresh_token : null;
        if (!refreshToken) {
            console.info(this.i18nService.get('TokenExpiration.noAccessTokenMsg'));
        }
        const body = new HttpParams()
            .set('secret', 'mySecretOAuthSecret')
            .set('grant_type', 'refresh_token')
            .set('refresh_token', refreshToken)
            .set('scope', 'spot')
            .set('client_id', 'siilaapp');
        return this.http
            .post(this.dev, body, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Accept', 'application/json')
                .set('Authorization', 'Basic c2lpbGFhcHA6bXlTZWNyZXRPQXV0aFNlY3JldA==')
        })
            .pipe(tap((authData) => {
            localStorage.setItem('token', JSON.stringify(authData));
        }), map((authData) => authData.access_token));
    }
    showMessage(header, message, keepUrl, cssClass) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const okText = this.i18nService.get('global.OK');
            const alert = yield this.alertCtrl.create({
                cssClass,
                header: header,
                message: message,
                buttons: [okText]
            });
            alert.onDidDismiss().then(() => {
                if (!keepUrl) {
                    this.router.navigateByUrl('/');
                }
            });
            yield alert.present();
        });
    }
    completeLoginProcess(account) {
        if (account && account.roles.includes('ROLE_LISTING')) {
            // this.dialog.close();
            // this.router.navigate(["listings"]);
            //this.broadcastChannel.postMessage({ payload: 'LOGIN' });
        }
        else {
            // this.windowRef.nativeWindow.location.href = this.windowRef.nativeWindow.location.hostname.includes(
            //   "localhost"
            // )
            //   ? `http://localhost:8080/#!/community`
            //   : `https://devapp.siila.com.br/#!/community`;
        }
    }
    registerDevice(account) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // const createdUserDevice = await this.deviceRegistration.createUserDevice();
            // this._log.info("created user device -> ", createdUserDevice);
            //Store cookie logic for new device
            // this._device.openDialog(null, RegistrationSteps.Success);
            // const registrationSuccessDialog = this._device.dialogRef;
            // registrationSuccessDialog.afterClosed().subscribe(e => {
            //   this.completeLoginProcess(account);
            // });
        });
    }
    logout() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (isPlatformBrowser(this.platformId)) {
                try {
                    yield this.http.post(`${environment.apiURL}/logout`, {}).toPromise();
                }
                catch (e) {
                    console.error('Terminating db session failed.', e);
                }
                this.removeCredentials();
                this.message_broadcast('logout');
                //this.broadcastChannel.postMessage('logout'); /* send */
                this.websocketService.send(SiilaBusAuthActions.KICK, JSON.stringify({ action: 'kick', data: 'kick' }));
                this.socketService.disconnect();
                if (this.router.url !== '/' && this.router.url !== '/') {
                    this.router.navigate(['/']);
                }
                else {
                    location.reload();
                }
            }
        });
    }
    removeCredentials() {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('ls.token');
        localStorage.removeItem(SelectedFilterKey.FAVORITES_SEARCH);
        this.account.accountLogout();
        this.$isLoginSubject.next(false);
    }
}
export var RegistrationSteps;
(function (RegistrationSteps) {
    RegistrationSteps["New"] = "new";
    RegistrationSteps["Existing"] = "existing";
    RegistrationSteps["Success"] = "success";
})(RegistrationSteps || (RegistrationSteps = {}));
