import { Injectable } from '@angular/core';
import { I18nService } from '../i18n.service';
import { RegisterService } from '../register.service';
import { BaseService } from '../../spot-buildings/services/base-service';
import { Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AppRouteNamesService } from './app-route-names.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { SavedSearchDTO } from '@app/models/saved-search-dashboard.model';
import { CompanyService } from '@app/company/services/company.service';
import { BrokersService } from '@app/brokers/services/brokers.service';

@Injectable()
export class RoutingHelperService extends BaseService {
  isMobile: boolean = false;

  constructor(
    private router: Router,
    private i18nService: I18nService,
    private breakpointObserver: BreakpointObserver,
    private appRouteNamesSrv: AppRouteNamesService,
    private translateService: TranslateService,
    private companyService: CompanyService,
    private brokerService: BrokersService
  ) {
    super();
    this.breakpointObserver.observe(Breakpoints.Handset).subscribe(result => {
      this.isMobile = result.matches;
    });
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      //console.log('Lang changed Routing helpser ', event, this.getSpotSearchURL());
    });
  }

  getSpotSearchURL = () => `/${this.appRouteNamesSrv.getSearchRouteName()}`;

  private concatLanguageToURL(url: string) {
    return this.i18nService.concatLangParamBasedOnURL(url);
  }

  navigateTo(url: string, state?: any, samePage?: boolean) {
    url = this.concatLanguageToURL(url);

    if (this.isMobile || samePage) {
      this.router.navigateByUrl(url, { state: state });
    } else {
      window.open(url, '_blank');
    }
  }

  async navigateToBrokerDetailsPage(brokerId: number) {
    const url: any = await this.brokerService.getBrokerURL(brokerId);
    this.navigateTo(url.detailsURL);
  }

  async navigateToCompanyDetailsPage(companyId: number, state?: any) {
    const companyURLFormat: any = await this.companyService.getCompanyDetailURL(companyId);
    let detailsURL = companyURLFormat.detailsURL;
    this.navigateTo(detailsURL);
  }

  navigateToSearchPage(avoidTargetBlank: boolean) {
    if (avoidTargetBlank) {
      this.router.navigateByUrl(this.getSpotSearchURL());
    } else {
      this.navigateTo(this.getSpotSearchURL());
    }
  }

  navigateToDefaultListingPropertySearchURL() {
    let url = `${this.getSpotSearchURL()}${this.appRouteNamesSrv.getDefaultInitSearchRouteName()}`;
    this.navigateTo(url, null, true);
  }

  navigateFavoritesPage() {
    const favoritesPath = `/${this.appRouteNamesSrv.getMySpotsRouteName()}`;
    this.navigateTo(favoritesPath, null, true);
  }

  navigateToSpotHomePage() {
    this.navigateTo('/', null, true);
  }

  navigateToSavedSearchPage(savedSearch: SavedSearchDTO) {
    const url = `${this.getSpotSearchURL()}/${this.appRouteNamesSrv.getSavedSearchRouteName()}/${savedSearch.user.id}/${
      savedSearch.id
    }`;
    if (this.isMobile) {
      this.router.navigateByUrl(url);
    } else {
      window.open(url, '_blank');
    }
  }

  navigateToSpotSetSearchPage(item: any) {
    let newUrl = `${item.spotSearchURL}/${this.concatLanguageToURL(
      this.appRouteNamesSrv.getFilterRouteName()
    )}?${this.appRouteNamesSrv.getSpotSetRouteName()}=${item.name}`;
    this.router.navigateByUrl(`${this.getSpotSearchURL()}${newUrl}`);
  }

  navigateToTermsPage() {
    this.navigateTo(this.appRouteNamesSrv.getTermsRouteName(), null, true);
  }

  navigateToPrivacyPolicyPage() {
    this.navigateTo(this.appRouteNamesSrv.getPrivacyPolyRouteName(), null, true);
  }
}
