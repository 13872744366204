import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { ImageService, PlatformHelperService, PropertyTypeHelperService, I18nService } from '@app/core';
import { AuthenticationService } from '@app/core/auth';
import { CommonGenericService } from '@app/core/common.service';
import { GenericMapService } from '@app/core/generic-map.service';
import { ListingDetailService, EventCheckerService } from '@app/core/listings';
import { UserActivityService } from '@app/core/user-activity/user-activity.service';
import { AppCarouselRestoreService } from '@app/search-navigation/components/carousel';
import { AppListingSliderService } from '@app/search-navigation/components/listing-slider';
import { CoworkingService } from '@app/search-navigation/services/coworking.service';
import { DetailPositionService } from '@app/search-navigation/services/detail-position.service';
import { LoginFormDialogService } from '@app/shared/login';
import {
  faAward,
  faBuilding,
  faExternalLinkAlt,
  faHeart,
  faMapMarkerAlt,
  faMarker,
  faShareAltSquare,
  faStar,
  faWarehouse
} from '@fortawesome/free-solid-svg-icons';
import { IonSlides, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { NgNavigatorShareService } from 'ng-navigator-share';
import { Subscription } from 'rxjs';
import { ListingOfferType } from '@app/models/transaction-type.model';
import { MyListingEditComponent } from '@app/shared/my-listing-edit/my-listing-edit.component';
import { PreviewImagesComponent } from '@app/shared/preview-images/preview-images.component';
import { SpotBuildingDetailDTO } from '../../../../models/spot-buildings/spot-buildings.model';
import { BrokersDTO } from '../../../../models/bokers.model';
import { SpotBuildingsService } from '@app/spot-buildings/services/spot-buildings.service';
import { SpacesFilterDTO } from '@app/models/spot-buildings/spot-available-spaces';
import { SpotBuildingSpaceService } from '../../../services/spaces.service';

@Component({
  selector: 'app-spot-building-card',
  templateUrl: './spot-building-card.component.html',
  styleUrls: ['./spot-building-card.component.scss']
})
export class SpotBuildingCardComponent implements OnInit {
  @ViewChild('photoSlider', { static: false }) photoSlider: IonSlides;
  @Input() listing: SpotBuildingDetailDTO;
  @Input() myListingPage?: boolean;
  @Input() fromCarousel?: boolean;
  @Input() myListingAnalytics?: boolean;
  // tslint:disable-next-line: no-input-rename
  @Input('currentPage') page: any;
  @Input() localStorageKey: string;
  @Input() activeListingIds: any;
  @Input() activeSlideIndex: any;
  @Input() loadedListings: any;
  @Input() totalListingCount: any;
  @Input() lazyLoadImages: boolean;
  @Input() isLoadFromMain?: boolean;
  @Input() searchCriteria?: any;
  @Input() checkBoxValues?: any;
  @Input() searchTags?: any;
  @Input() cardImgWithPadding?: boolean;
  @Input() defaultCoworkingType: any;

  brokers: BrokersDTO[];
  listingSubtypes: string;
  isMobile: boolean = false;
  defaultImage: string = this.platformConfigHelper.Defaults().imagePlaceholder;
  staticMapImageUrl: string;
  currentPhotoIndex = 0;
  prevCurrentPhotoIndex: number = 0;
  counter: number = 0;
  hasPointer: boolean;
  faIcon: any;
  dialogRef: any;
  propertyFaIcon: any;
  faShareAltSquare: any;
  markers: any[];
  buildingListingPhotosAndMap: any[];
  propertyType: any;
  spotBuildingTypeTranslation: any;
  faHeart: any;
  fastar: any;
  faExternalLinkAlt: any;
  faMarker: any;
  loginSubscription: Subscription;
  mapOptions: any = {
    singleMarker: true,
    ignoreZoom: true,
    zoom: 15
  };
  screenWidth: any;
  photosSet: boolean = false;
  isLoggedIn: any;
  customLoginText: Array<string>;
  translateSubscription: Subscription;
  sharedPrivatePositionsText: string;
  count: number = 0;
  notPhotos: boolean = false;
  spotImgURL: string;
  propertyTypeLbl: string;
  listingTypeLbl: string;
  spotName: string;

  constructor(
    private imageService: ImageService,
    private router: Router,
    private detailPositionService: DetailPositionService,
    private carouselRestoreService: AppCarouselRestoreService,
    private platformConfigHelper: PlatformHelperService,
    private dialog: MatDialog,
    private commonService: CommonGenericService,
    private commomMapSvc: GenericMapService,
    private breakpointObserver: BreakpointObserver,
    private propertyHelperService: PropertyTypeHelperService,
    private i18nService: I18nService,
    private ngNavigatorShareService: NgNavigatorShareService,
    private toastCtrl: ToastController,
    private spotBuildingsService: SpotBuildingsService,
    private auth: AuthenticationService,
    private spacesService: SpotBuildingSpaceService,
    private _ts: TranslateService,
    private userActivityService: UserActivityService,
    private coworkingService: CoworkingService,
    private listingSliderService: AppListingSliderService
  ) {
    this.faShareAltSquare = faShareAltSquare;
    this.screenWidth = window.innerWidth;
    this.breakpointObserver.observe(Breakpoints.Handset).subscribe(result => {
      this.isMobile = result.matches;

      if (!this.isMobile) {
        const mqStandAlone = '(display-mode: standalone)';
        if (window.matchMedia(mqStandAlone).matches) {
          this.isMobile = window.matchMedia(mqStandAlone).matches;
        }
      }
    });
  }

  ngOnInit(): void {
    const isOffice = this.commonService.isPropertyType(2001, this.listing);
    if (this.listing.buildingListingPhotos) {
      this.removeMap();
    }
    this.loginSubscription = this.auth.$isLoginSubject.subscribe(val => (this.isLoggedIn = val));
    this.translateSubscription = this._ts.get('global.form.favoriteSignIn', {}).subscribe((res: Array<string>) => {
      this.customLoginText = res;
    });
    this.hasPointer = window.matchMedia('(pointer: fine)').matches;
    this.faIcon =
      this.listing && this.listing.listingType && this.listing.listingType.toLowerCase() === 'featured'
        ? faStar
        : faAward;
    this.propertyFaIcon = isOffice ? faBuilding : faWarehouse;
    this.markers = [this.commonService.createMapMarker(this.listing)];
    this.staticMapImageUrl = this.commomMapSvc.generateStatiMapRequest(this.listing);
    this.notPhotos = this.doesNotPhotos();

    if (this.notPhotos) {
      this.spotImgURL = this.staticMapImageUrl ? this.staticMapImageUrl : this.defaultImage;
    } else {
      this.spotImgURL =
        this.listing.buildingListingPhotos[0] && this.listing.buildingListingPhotos[0].image
          ? this.getMediumImg(this.getCroppedOrOriginalImage(this.listing.buildingListingPhotos[0]))
          : this.staticMapImageUrl;
    }

    this.photosSet = true;
    this.faHeart = faHeart;
    this.fastar = faStar;
    this.faExternalLinkAlt = faExternalLinkAlt;
    this.faMarker = faMapMarkerAlt;
    this.propertyType = this.propertyHelperService.getPropertyTypeTranslationName(this.listing.buildingTypeId);
    this.spotBuildingTypeTranslation = this.propertyHelperService.getSpotBuildingTypeTranslation(this.listing.type);

    this.propertyTypeLbl = this.i18nService.get('global.menu.entities.buildingType') + ':';
    this.listingTypeLbl = this.i18nService.get('global.menu.entities.listingType') + ':';
    this.setSharedPrivatePositionsText();

    this.brokers = [];
    if (this.listing.brokers) {
      this.brokers = [...this.listing.brokers];
      if (this.brokers && this.brokers.length > 2) {
        const copy = this.listing.brokers.sort(
          (a: any, b: any) => (a != null ? a : Infinity) - (b != null ? b : Infinity)
        );
        if (copy && copy.length > 2) {
          this.brokers = [...copy];
          this.brokers.splice(0, copy.length - 2);
        }
      }
    }

    this.listing.availableAreaIni = 1000;
    this.listing.availableAreaEnd = 54000;
    this.spotName = this.i18nService.getTranslation(this.listing.buildingTitle);
  }

  ngOnDestroy() {
    this.loginSubscription.unsubscribe();
    this.translateSubscription.unsubscribe();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenWidth = event.target.innerWidth;
  }

  getHeaderCard() {
    return this.i18nService.getTranslation(this.listing.cityName);
  }

  getCityState() {
    return `${this.i18nService.getTranslation(this.listing.cityName)} - ${this.i18nService.getTranslation(
      this.listing.stateName
    )}`;
  }

  getTotalBuildingArea() {
    return 105000;
  }

  doesNotPhotos() {
    if (!this.listing.buildingListingPhotos) {
      return true;
    }

    return this.listing.buildingListingPhotos.length == 0;
  }

  removeMap() {
    Object.keys(this.listing.buildingListingPhotos).forEach((key: any) => {
      if (this.listing.buildingListingPhotos[key] && !this.listing.buildingListingPhotos[key].image) {
        this.listing.buildingListingPhotos.splice(key, 1);
      }
    });
  }

  public isEndOfSlide(): boolean {
    return this.currentPhotoIndex === this.listing.buildingListingPhotos.length - 1;
  }

  getCroppedOrOriginalImage(listingPhoto: any) {
    if (listingPhoto) {
      if (listingPhoto.croppedImage && listingPhoto.croppedImage.id) {
        return listingPhoto.croppedImage.id;
      }
      return listingPhoto.image.id;
    }
  }

  public getMediumImg(id: any) {
    return this.imageService.mediumThumbnail(id);
  }

  public getCompanyLogoImg(id: any) {
    return id ? this.getMediumImg(id) : '../../../assets/no-company-logo.png';
  }

  getTypeText() {
    return this.commonService.getTypeText(this.listing.type);
  }

  slideToIndex(event: Event, index: number) {
    event.stopPropagation();
    this.photoSlider.slideTo(index);
  }

  async openEditListingMenu(event: any, data?: any) {
    event.preventDefault();
    event.stopPropagation();
    console.log('Open Edit Listing Menu');
    //data = await this.listingService.getRevisionOrActiveVersionBasedOnUserRole(data);

    this.dialogRef = this.dialog.open(MyListingEditComponent, {
      height: 'auto',
      width: '550px',
      data: data ? { data: data, myListingPage: this.myListingPage, date: new Date() } : null
    });

    this.dialogRef.afterClosed().subscribe((result: any) => {});
  }

  openPreviewComponent($event: Event): void {
    $event.stopImmediatePropagation();
    this.dialogRef = this.dialog.open(PreviewImagesComponent, {
      height: '78%',
      width: '60%',
      data: this.listing,
      panelClass: 'custom-modalbox'
    });

    this.dialogRef.afterClosed().subscribe((result: any) => {});
  }

  public async openCard(id: number, $event: Event, externalLink = false) {
    const state: any = {
      localStorageKey: this.localStorageKey,
      searchCriteria: this.searchCriteria,
      searchTags: this.searchTags,
      checkBoxValues: this.checkBoxValues,
      myListingAnalytics: this.myListingAnalytics,
      myListingPage: this.myListingPage,
      openUrl: true
    };
    $event.stopImmediatePropagation();

    let lang = this.i18nService.getCurrentLanguage();
    if (window.location.href.includes('/es')) {
      lang = 'es';
    } else if (window.location.href.includes('/en')) {
      lang = 'en';
    }

    let res: any = await this.spotBuildingsService.getSpotBuildingDetailURLFormat(id, lang).toPromise();
    if (res) {
      let detailsURL = res.detailsURL;
      if (this.i18nService.currentUrlContainsCurrentLanguage()) {
        detailsURL = this.i18nService.addCurrentLanguageToPath(detailsURL);
      }
      if (externalLink) {
        if (window.matchMedia('(display-mode: standalone)').matches) {
          this.router.navigateByUrl(detailsURL);
        } else {
          window.open(detailsURL, '_blank');
        }
        return;
      }

      let spacesFilter: SpacesFilterDTO = Object.assign({}, this.searchCriteria);
      this.spacesService.saveSpacesFilter(spacesFilter);
      if (this.isMobile) {
        this.router.navigateByUrl(detailsURL, { state: state });
      } else {
        if (window.matchMedia('(display-mode: standalone)').matches) {
          this.router.navigateByUrl(detailsURL);
        } else {
          window.open(detailsURL, '_blank');
        }
      }
      this.detailPositionService.setPositionData(this.activeListingIds);
    }
  }

  public async routeToDetailsPage(id: any) {
    let res: any = await this.spotBuildingsService.getSpotBuildingDetailURLFormat(id, 'en').toPromise();
    if (res) {
      let searchPageURL = res.detailsURL;
      if (this.i18nService.currentUrlContainsCurrentLanguage()) {
        searchPageURL = this.i18nService.addCurrentLanguageToPath(`/detail/${id}`).split('/');
      }
      if (!searchPageURL[0].startsWith('/')) {
        searchPageURL[0] = '/'.concat(searchPageURL[0]);
      }
      return searchPageURL;
    }
  }

  public getLargeImg(id: any) {
    return this.imageService.largeThumbnail(id);
  }

  public getFullImagePath(id: any) {
    return this.imageService.getFullImagePath(id);
  }

  public getPreviousPhoto() {
    if (this.currentPhotoIndex === 0) {
      this.photoSlider.slideTo(this.listing.buildingListingPhotos.length);
    } else {
      this.photoSlider.slidePrev();
    }
    this.updatePhotoIndex();
  }

  public getNextPhoto(id: number, $event: Event) {
    this.photoSlider.getActiveIndex().then(index => {
      if (index < this.currentPhotoIndex) {
        this.currentPhotoIndex = index;
      } else {
        if (this.currentPhotoIndex === this.listing.buildingListingPhotos.length - 1 && this.count == 0) {
          this.openCard(id, $event);
        } else {
          if ($event.type === 'click') {
            this.photoSlider.slideNext();
            this.updatePhotoIndex();
            this.count = 1;
          } else {
            if (this.count == 1) {
              this.count = 0;
            } else {
              this.updatePhotoIndex();
            }
          }
        }
      }
    });
  }

  public updatePhotoIndex() {
    this.photoSlider.getActiveIndex().then(index => {
      this.currentPhotoIndex = index;
    });
  }

  async getUrlUpdate() {
    let lang = this.i18nService.getCurrentLanguage();
    let res: any = await this.spotBuildingsService.getSpotBuildingDetailURLFormat(this.listing.id, lang).toPromise();
    let detailsURL: any;
    if (res) {
      detailsURL = res.detailsURL;
      if (this.i18nService.currentUrlContainsCurrentLanguage()) {
        detailsURL = this.i18nService.addCurrentLanguageToPath(detailsURL);
      }
    }
    return detailsURL;
  }

  public async shareLink($event: Event, listingId: number) {
    $event.stopImmediatePropagation();
    this.userActivityService.trackListingShare(listingId);
    let res: any = await this.getUrlUpdate();
    this.ngNavigatorShareService
      .share({
        title: this.i18nService.getTranslation(this.listing.title),
        text: this.i18nService.get('global.listing-card.shareLinkText'),
        url: environment.spotServerUrl + res
      })
      .catch(async error => {
        if (error) {
          let text = environment.spotServerUrl + res;
          let result: any = this.copyTextToClipboard(text);
          if (result) {
            const toast = await this.toastCtrl.create({
              message: this.i18nService.get('global.listing-card.desktopShareText'),
              duration: 3500,
              animated: true,
              keyboardClose: true,
              cssClass: 'toast-alert'
            });
            toast.onDidDismiss().then(() => {});
            return await toast.present();
          }
        }
      });
    return false;
  }

  async copyTextToClipboard(text: any) {
    var txtArea = document.createElement('textarea');
    txtArea.id = 'txt';
    txtArea.style.position = 'fixed';
    txtArea.style.top = '0';
    txtArea.style.left = '0';
    txtArea.style.opacity = '0';
    txtArea.value = text;
    document.body.appendChild(txtArea);
    txtArea.select();
    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      if (successful) {
        return true;
      }
    } catch (err) {
    } finally {
      document.body.removeChild(txtArea);
    }
    return false;
  }

  public addColon(propertySubTypes: any) {
    return propertySubTypes && propertySubTypes.length ? ':' : '';
  }

  getMeasurementValue() {
    if (this.listing.measurementStandard) {
      return this.i18nService.getTranslation(this.listing.measurementStandard.measurement);
    } else {
      return this.i18nService.get('global.coworking.availableSpace');
      //return 'Available Space!';
    }
  }

  public hasSubtypeOnMobile(): boolean {
    return [
      this.isLoadFromMain,
      this.isMobile,
      this.listing
      //this.listing.propertySubTypes && this.listing.propertySubTypes.length
    ].every(condition => condition);
  }

  public isCompanySameAsAUser(listing: any) {
    return listing && listing.offeredByUser.company.name === listing.offeredByUser.firstNameAndLastName;
  }

  public showPrevNextNav() {
    return [
      this.hasPointer,
      this.listing.buildingListingPhotos && this.listing.buildingListingPhotos.length > 1,
      !this.myListingPage
    ].every(condtion => condtion);
  }

  public viewMap($event: Event) {
    $event.stopImmediatePropagation();
    this.updatePhotoIndex();
    this.photoSlider.slideTo(this.listing.buildingListingPhotos.length);
  }

  public async loopSlide() {
    const numberOfSlide = this.listing.buildingListingPhotos.length;
    const isBeginging = await this.photoSlider.isBeginning();
    const isEnd = await this.photoSlider.isEnd();
    const prev = await this.photoSlider.getPreviousIndex();
    if (this.currentPhotoIndex === 0 && isBeginging) {
      this.photoSlider.slideTo(this.listing.buildingListingPhotos.length);
    } else if (this.currentPhotoIndex === numberOfSlide - 1 && isEnd) {
      this.photoSlider.slideTo(0);
    }
    this.updatePhotoIndex();
  }

  public isRentCondoIPTUNegotiables() {
    return false;
  }

  hasCoworkingSubType(listing: { id: number; isForLeaseSale: string }) {
    return listing.isForLeaseSale === ListingOfferType.Coworking;
  }

  private setSharedPrivatePositionsText() {
    const showCoworkingPrivate = this.defaultCoworkingType && this.defaultCoworkingType == 'private';
    this.sharedPrivatePositionsText = this.coworkingService.getSharedPrivatePositionsText(
      this.listing,
      showCoworkingPrivate
    );
  }

  checkRole(role: string) {
    try {
      let user = JSON.parse(localStorage.getItem('user'));
      return user && user.roles ? user.roles.includes(role) : false;
    } catch (e) {
      console.log('roles undefined --> ', e);
    }
  }

  private buildListingDetailsURL(listingId: number) {
    return `${environment.spotServerUrl}/detail/${listingId}`.concat(this.i18nService.getLanguagePathURL());
  }

  changeSlide(event: any) {
    let swipeDirection = event.srcElement.dom7ElementDataStorage.swiper.swipeDirection;
    this.listingSliderService.setSlide(swipeDirection);
  }

  getPropertyTypeText() {
    return this.commonService.getPropertyTypeText(this.listing.buildingTypeId);
  }

  getAvailableAreaText() {
    return this.commonService.getAvailableAreaText(this.listing.spaceRangesDTO);
  }

  getAvgAskingRent() {
    return this.commonService.getAvgAskingRent(this.listing, this.listing.type);
  }
}
