import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { I18nService } from '@app/core';
import { MetaService } from '@app/core/seo';
import { ListingMetatag } from '@app/models/spot-buildings/spot-buildings-types';
import { SpotBuildingDTO } from '@app/models/spot-buildings/spot-buildings.model';
import { ListingType } from '@app/models/transaction-type.model';
import { CreateSpotBuildingsService } from '@app/spot-buildings/services/create-spot-buildings.service';

@Component({
  selector: 'app-spot-detail-container',
  templateUrl: './spot-detail-container.component.html',
  styleUrls: ['./spot-detail-container.component.scss']
})
export class SpotDetailContainerComponent implements OnInit {
  buildingDetail: SpotBuildingDTO;
  isPromoted: boolean;
  isSpotFetched: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private metaService: MetaService,
    private createSpotBuildingsService: CreateSpotBuildingsService
  ) {}

  async ngOnInit() {
    this.getMetatags();
    await this.getParamsUrl();
  }

  private async getParamsUrl() {
    console.log('Get Params URL');
    this.activatedRoute.params.subscribe(paramMap => {
      try {
        const buildingIdParam = paramMap.buildingId;
        const listingTypeParam = paramMap.buildingType;
        this.createSpotBuildingsService
          .getSpotBuildingsFromBuilding(buildingIdParam, listingTypeParam, paramMap.lang)
          .then((response: SpotBuildingDTO) => {
            this.buildingDetail = response;
            this.isPromoted = this.buildingDetail.listingType == ListingType.Promoted;
            setTimeout(() => {
              this.isSpotFetched = true;
              console.log('Spot Fetched ', this.isSpotFetched);
            }, 300);
          });
      } catch (error) {
        console.error(error);
      }
    });
  }

  private async getMetatags() {
    console.log('Get metatags data');
    this.activatedRoute.params.subscribe(paramMap => {
      try {
        const buildingIdParam = paramMap.buildingId;
        const listingTypeParam = paramMap.buildingType;
        console.log('Param: ', paramMap);
        this.createSpotBuildingsService
          .getSpotBuildingsMetadataFromBuilding(buildingIdParam, listingTypeParam)
          .then((response: ListingMetatag) => {
            console.log('Metatags Response ', response);
            this.metaService.setSpotBuildingMetaTags2(response, []);
            (window as any).prerenderReady = true;
          });
      } catch (error) {
        console.error(error);
      }
    });
  }
}
